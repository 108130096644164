import { inject }        from 'aurelia-framework';
import { AppContainer }  from 'resources/services/app-container';
import { BaseViewModel } from 'base-view-model';

@inject(AppContainer)
export class Dashboard extends BaseViewModel {

    //

}
