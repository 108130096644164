import { bindable, containerless, inject } from 'aurelia-framework';
import { RelativeTime }                    from 'aurelia-i18n';
import { AppContainer }                    from 'resources/services/app-container';
import { NotificationsRepository }         from 'modules/personal-area/notifications/services/repository';
import { UsersRepository }                 from 'modules/administration/users/services/repository';

@containerless()
@inject(AppContainer, RelativeTime, NotificationsRepository, UsersRepository)
export class NotificationsCard {
    @bindable headerTitle = 'dashboard.title.notifications';

    criteria = {
        status_id_exclude: 3,
    };

    notifications = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param relativeTime
     * @param repository
     * @param usersRepository
     */
    constructor(appContainer, relativeTime, repository, usersRepository) {
        this.appContainer    = appContainer;
        this.relativeTime    = relativeTime;
        this.repository      = repository;
        this.usersRepository = usersRepository;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.fetchData();
    }

    /**
     * Fetches data from server
     *
     * @returns {*}
     */
    fetchData() {
        return this.usersRepository.unreadNotifications().then((notifications) => {
            this.notifications.splice(0, this.notifications.length, ...notifications);
        });
    }

    /**
     * Handles view action button
     *
     * @param row
     */
    viewNotificationHandler(row) {
        if (row.relatable && row.route) {
            let route = this.appContainer.router.generate(row.route, {id: row.relatable.id});

            // marks the task has read
            this.repository.read(row.id).then(() => this.appContainer.router.navigate(route));
        }
    }

    /**
     * Handles dismiss action button
     *
     * @param row
     */
    dismissNotificationHandler(row) {

        let options = {
            title:              this.appContainer.i18n.tr('text.attention'),
            text:               this.appContainer.i18n.tr('form.message.want-dismiss-notification'),
            type:               'warning',
            showCancelButton:   true,
            cancelButtonText:   this.appContainer.i18n.tr('form.button.cancel'),
            confirmButtonColor: '#FF7043',
            confirmButtonText:  this.appContainer.i18n.tr('form.button.ok'),
            closeOnConfirm:     false,
        };

        let callback = (confirmed) => {
            if (confirmed) {
                // marks the task has dismissed
                this.repository
                    .dismiss(row.id)
                    .then((response) => {
                        if (response.status === true) {
                            this.appContainer.swalService.successAlert();
                            this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', {listingId: this.listingId});
                        } else {
                            this.appContainer.swalService.errorAlert();
                        }
                    });
            }
        };

        this.appContainer.swalService.customAlert(options, callback);
    }

    /**
     * Gets relative time from a given date
     *
     * @param date
     *
     * @returns {*|any}
     */
    getRelativeTime(date) {
        let time = new Date(date);

        return this.relativeTime.getRelativeTime(time);
    }
}
